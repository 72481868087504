import styled from "styled-components";

const InputNumber=styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
    position: relative;
    width: 120px;
    height: 40px;    
    overflow: hidden;
    border-radius: 20px;
    margin: 10px;
      
  background-color: ${({theme}) => theme.primary};
  font-family: ${({theme}) => theme.font.family.Montserrat};

span {
    position: absolute;
    left: 50%;
    margin-left: -20px;
    display: inline-block;
    background-color: white;
    height: 100%;
    width: 40px;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: black;
  font-weight: 600;
  

}

 button {
    display: inline-block;
    width: 60px;
    height: 100%;   
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
   border-radius: 20px;
   background-color: ${({theme}) => theme.honey};
    transition: background-color .2s ease;
}

 button:nth-of-type(1) {
    padding-right: 25px
}

 button:nth-of-type(2) {
    padding-left: 20px;
}

 button:hover {
   background-color: rgba(255,255,255,.2);
   transition: background-color .2s ease;
}`;

export default InputNumber;